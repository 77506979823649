<template>
  <div id="notes" class="lg:flex">
    <sidebar>
      <div class="sidebar-menu-wrapper">
        <div class="pt-3 folder">
          <router-link to="/app/notes/all" class="block py-1.5 pr-2.5 pl-6" :class="{ 'router-link-exact-active': $route.params.folderId === 'all' }">
            All Notes <span class="small-counter">{{ counterDisplay(notes.filter(note => !note.archived).length)}}</span>
          </router-link>
        </div>
        <div class="folder" v-for="folder in folders.slice(0, 1)" :key="folder._id">
          <router-link :to="'/app/notes/' + folder._id" class="block py-1.5 pr-2.5 pl-6" :class="{ 'router-link-exact-active': $route.params.folderId === folder._id }">
            <span>{{ folder.title }}</span>
            <span class="small-counter">{{
              counterDisplay(notes.filter(note => !note.archived && (note.folderId === folder._id)).length)
            }}</span>
          </router-link>
        </div>
        <folder-form></folder-form>
        <folders-draggable
          :folders="parentFolders"
          :count="countNotesInFolder"
          :link="'notes'"
        >
        </folders-draggable>
        <div class="pb-6 mt-auto folder"><router-link to="/app/notes/archived" class="block py-1.5 pr-2.5 pl-6">Archived</router-link></div>
      </div>
    </sidebar>
    <div class="grid overflow-hidden p-0 w-full transition-all duration-200 ease-out grid-cols-notes lg:grid-cols-notes-lg lg:flex-grow" :class="{ 'lg:-ml-60': openSidebar }">
      <note-list
        @create-note="createNoteBlank"
        @create-note-template="createNoteTemplate"
        @select-note="selectNote"
        @delete-all-archived="deleteAllArchivedNotes"
        :loading="notesLoading"
        :selectedFolder="selectedFolder"
        :selectedNoteId="selectedNoteId"
        :filteredNotes="filteredNotes"
        :showNoteListDesktop="showNoteListDesktop"
        :showNoteListMobile="showNoteListMobile"/>
      <div class="relative z-20 bg-white transition-all duration-200 transform note-container" :class="[ showNoteListMobile ? 'translate-x-0' : '-translate-x-full lg:translate-x-0', showNoteListDesktop ? 'lg:ml-0' : 'lg:-ml-90 ease-out']">
        <div v-if="!noteInStore" class="flex justify-center items-center h-full text-gray-400">
          <span>Select or <a class="pb-px text-gray-500 border-b border-gray-500 hover:border-black hover:text-black" @click.prevent="createNoteBlank">create a note</a> to start editing</span>
        </div>
        <div v-else class="overflow-y-auto relative py-12 h-screen">
          <note
            :key="noteInStore._id"
            :note="noteInStore"
            :selectedNoteId="selectedNoteId"
            :openSidebar="openSidebar"
            :folders="sortedFolders"
            :showNoteListDesktop="showNoteListDesktop"
            @toggle-desktop-full="toggleDesktopFull"
            @toggle-note-list-container="toggleNoteListContainer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import { differenceInSeconds, parseISO } from 'date-fns'
import { getNoteTemplate } from '../../utils'

import Folders from '@/mixins/Folders'
import Sidebar from '@/mixins/Sidebar'

// import Loading from '@/components/Loading'
import Note from '@/components/Note'
import NoteList from '@/components/NoteList'

export default {
  name: 'Notes',
  mixins: [
    Folders,
    Sidebar,
    makeFindMixin({ service: 'notes', watch: true })
  ],
  components: { Note, NoteList },
  data() {
    return {
      showNoteListDesktop: true,
      showNoteListMobile: true,
      newNote: {
        title: 'Untitled Note',
        content: '',
        folderId: ''
      }
    }
  },
  computed: {
    ...mapState(['selectedNoteId']),
    ...mapGetters(['connected']),
    ...mapGetters('notes', { getNoteInStore: 'get' }),
    ...mapGetters('tasks', { findTasksInStore: 'find' }),
    notesParams() { return { query: { $sort: { updatedAt: -1 } }, temps: !this.connected } },
    notesQueryWhen() { return this.connected },
    notesLoading() { return this.isFindNotesPending && !this.haveNotesLoadedOnce },
    filteredNotes() {
      let sortedNotes
      if (this.$route.params.folderId === 'all') {
        sortedNotes = this.notes.filter(note => !note.archived)
      } else if (this.$route.params.folderId === 'archived') {
        sortedNotes = this.notes.filter(note => note.archived)
      } else {
        sortedNotes = this.notes.filter(note => !note.archived && note.folderId === this.$route.params.folderId)
      }
      return sortedNotes.sort((left, right) => {
        return differenceInSeconds(parseISO(right.updatedAt), parseISO(left.updatedAt))
      })
    },
    noteInStore() {
      return this.getNoteInStore(this.selectedNoteId)
    }
  },
  watch: {
    '$route.params.noteId' (to) {
      this.viewNote(to)
    },
    '$route.meta' ({ showNoteList }) {
      this.showNoteListMobile = showNoteList
    }
  },
  mounted() {
    if (this.$route.params.noteId) {
      this.viewNote(this.$route.params.noteId)
    }
  },
  methods: {
    countNotesInFolder(folderId) {
      return this.notes.filter(note => !note.archived && note.folderId === folderId).length
    },
    counterDisplay(num) {
      return num > 0 ? num > 999 ? '999+' : num : 0
    },
    createNote(title, template) {
      if (this.$route.params.folderId === 'all') {
        this.$set(this.newNote, 'folderId', this.folders[0]._id)
      } else {
        this.$set(this.newNote, 'folderId', this.selectedFolder._id)
      }
      this.$set(this.newNote, 'title', title)
      this.$set(this.newNote, 'content', template)
      if (title === 'Daily Journal') {
        this.$set(this.newNote, 'endDate', new Date())
      }
      const { Note } = this.$FeathersVuex.api
      const note = new Note(this.newNote)
      note.save().then(createdNote => {
        this.viewNote(createdNote._id)
        this.newNote = {
          title: 'Untitled Note',
          content: '',
          folderId: ''
        }
      })
    },
    createNoteBlank() {
      this.createNote('Untitled Note', '')
    },
    createNoteTemplate(type) {
      switch (type) {
        case 'meeting':
          this.createNote('Meeting Note', getNoteTemplate(type))
          break
        case 'journal':
          this.createNote('Daily Journal', getNoteTemplate(type))
          break
        case 'project':
          this.createNote('Project Plan', getNoteTemplate(type))
          break
      }
    },
    selectNote(noteId) {
      if (this.$route.params.noteId !== noteId) {
        this.$router.push({ path: `/app/notes/${this.$route.params.folderId}/${noteId}` })
      }
    },
    viewNote(noteId) {
      this.$store.commit('setSelectedNoteId', noteId)
      this.toggleNoteListContainer(false)
      this.$el.querySelector('.note-container > div').scrollTop = 0
    },
    toggleNoteListContainer(bool) {
      this.showNoteListMobile = bool
    },
    toggleDesktopFull(bool) {
      if (this.showNoteListDesktop === true && this.openSidebar === true) {
        this.showNoteListDesktop = false
        this.$store.commit('toggleSidebar', true)
      } else {
        this.showNoteListDesktop = !bool
        if (this.openSidebar !== bool) { this.$store.commit('toggleSidebar', bool) }
      }
    },
    deleteAllArchivedNotes() {
      const result = confirm('Are you sure want to permanently DELETE all archived notes?')
      if (result) {
        this.$store.dispatch('notes/find', { query: { archived: true } })
          .then(note => {
            note.forEach(note => { note.remove() })
          }).catch(err => console.error(err))
      }
    }
  }
}
</script>
