import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class User extends BaseModel {
  static modelName = 'User'

  static instanceDefaults() {
    return {
      email: '',
      password: '',
      displayName: '',
      isVerified: false,
      verifyExpires: '',
      verifyToken: '',
      settings: JSON.stringify({
        deskCompactPinnedNotes: true,
        deskCompactRecentNotes: false,
        deskShowRecentNotes: true,
        deskShowTomorrowTasks: true,
        calendarShowTasks: true,
        calendarShowNotes: true,
        calendarShowWeekend: true
      })
    }
  }
}

const servicePath = 'users'
const servicePlugin = makeServicePlugin({
  Model: User,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
