<template>
  <div id="main-app" class="lg:pl-16">
    <nav class="fixed bottom-0 left-0 z-40 p-0 m-0 w-full h-14 bg-white border-t border-gray-300 lg:border-t-0 lg:border-r lg:h-full lg:w-16">
      <div class="flex justify-around items-center w-full h-full lg:flex-col lg:justify-start">
        <div class="flex justify-center items-center w-full h-full lg:h-16">
          <a
            class="flex flex-col justify-center items-center p-3 w-full h-14 opacity-25 transition-all cursor-pointer lg:h-auto lg:w-auto lg:rounded-full hover:bg-gray-100 hover:opacity-100"
            title="Desk"
            @click.prevent="navigateTo('/app')"
            :class="{ 'bg-gray-100 lg:bg-transparent opacity-80': this.$route.name === 'Desk' }"
          >
            <img src="@/assets/icons/icon-nav-desk.svg" alt="nav-desk" width="24" height="24"/>
            <span class="block mt-1 text-xs text-black lg:hidden">Desk</span>
          </a>
        </div>
        <div class="flex justify-center items-center w-full h-full lg:h-16">
          <a
            class="flex flex-col justify-center items-center p-3 w-full h-14 opacity-25 transition-all cursor-pointer lg:h-auto lg:w-auto lg:rounded-full hover:bg-gray-100 hover:opacity-100"
            title="Notes"
            @click.prevent="navigateTo('/app/notes')"
            :class="{ 'bg-gray-100 lg:bg-transparent opacity-80': this.$route.name === 'NotesProject' || this.$route.name === 'Note' }"
          >
            <img src="@/assets/icons/icon-nav-note.svg" alt="nav-note" width="24" height="24"/>
            <span class="block mt-1 text-xs text-black lg:hidden">Notes</span>
          </a>
        </div>
        <div class="flex justify-center items-center w-full h-full lg:h-16">
          <a
            class="flex flex-col justify-center items-center p-3 w-full h-14 opacity-25 transition-all cursor-pointer lg:h-auto lg:w-auto lg:rounded-full hover:bg-gray-100 hover:opacity-100"
            title="Tasks"
            @click.prevent="navigateTo('/app/tasks')"
            :class="{ 'bg-gray-100 lg:bg-transparent opacity-80': this.$route.name === 'TasksProject' }"
          >
            <img src="@/assets/icons/icon-nav-task.svg" alt="nav-task" width="24" height="24"/>
            <span class="block mt-1 text-xs text-black lg:hidden">Tasks</span>
          </a>
        </div>
        <div class="flex justify-center items-center w-full h-full lg:h-16">
          <a
            class="flex flex-col justify-center items-center p-3 w-full h-14 opacity-25 transition-all cursor-pointer lg:h-auto lg:w-auto lg:rounded-full hover:bg-gray-100 hover:opacity-100"
            title="Calendar"
            @click.prevent="navigateTo('/app/calendar')"
            :class="{ 'bg-gray-100 lg:bg-transparent opacity-80': this.$route.name === 'CalendarProject' }"
          >
            <img src="@/assets/icons/icon-nav-calendar.svg" alt="nav-calendar" width="24" height="24"/>
            <span class="block mt-1 text-xs text-black lg:hidden">Calendar</span>
          </a>
        </div>
        <div class="hidden justify-center items-center mt-5 mb-auto w-full h-full lg:h-16 lg:flex">
          <a
            class="flex flex-col justify-center items-center p-3 w-full h-14 opacity-25 transition-all cursor-pointer lg:h-auto lg:w-auto lg:rounded-full hover:bg-gray-100 hover:opacity-100"
            v-if="$route.name !== 'Desk' && $route.name !== 'Settings'"
            @click.prevent="toggleSidebar" title="Toggle Sidebar"
          >
            <img src="@/assets/icons/icon-nav-menu.svg" alt="nav-menu" width="24" height="24"/>
            <span class="block mt-1 text-xs text-black lg:hidden">Menu</span>
          </a>
        </div>
        <div class="flex justify-center items-center w-full h-full lg:h-16">
          <a
            class="flex flex-col justify-center items-center p-3 w-full h-14 opacity-25 transition-all cursor-pointer lg:h-auto lg:w-auto lg:rounded-full hover:bg-gray-100 hover:opacity-100"
            title="Settings"
            @click.prevent="navigateTo('/app/settings')"
            :class="{ 'bg-gray-100 lg:bg-transparent opacity-80': this.$route.name === 'Settings' }"
          >
            <div class="relative">
              <div v-show="!connected" class="absolute -top-1.5 -right-1.5 w-1.5 h-1.5 bg-red-500 rounded" title="Offline"></div>
              <img src="@/assets/icons/icon-nav-settings.svg" width="24" height="24"/>
            </div>
            <span class="block mt-1 text-xs text-black lg:hidden">Settings</span>
          </a>
        </div>
      </div>
    </nav>
    <transition mode="out-in" enter-class="opacity-0" enter-active-class="transition-all" leave-active-class="transition-all" leave-to-class="opacity-0">
      <div v-if="loadProgress < 100" class="flex flex-col justify-center items-center w-full h-screen">
        <div class="relative mb-6 w-10 h-10">
          <loading/>
        </div>
        <div class="w-60 bg-gray-200 rounded-xl">
          <div class="h-1 bg-green-400 rounded-xl transition-all" :style="`width: ${loadProgress}%`"></div>
        </div>
      </div>
      <main v-else>
        <router-view></router-view>
      </main>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/components/Loading'

export default {
  name: 'MainApp',
  components: { Loading },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState(['loadProgress', 'connected', 'openSidebar'])
  },
  methods: {
    toggleSidebar() {
      this.$store.commit('toggleSidebar')
    },
    navigateTo(route) {
      this.$router.push(route, undefined, () => null)
      this.$store.commit('toggleSidebar', false)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/tooltip.scss';

</style>

<style>
@import '../../assets/styles/main-app.css';

</style>
