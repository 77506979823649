<template>
  <div id="calendar" class="lg:flex">
    <sidebar>
      <div class="sidebar-menu-wrapper">
        <div class="pt-3 folder">
          <router-link to="/app/calendar/all" class="block py-1.5 pr-2.5 pl-6" :class="{ 'router-link-exact-active': $route.params.folderId === 'all' }">
            <span>All Items</span>
            <span class="small-counter">{{ countAllEvents }}</span>
          </router-link>
        </div>
        <div class="folder" v-for="folder in folders.slice(0, 1)" :key="folder._id">
          <router-link :to="'/app/calendar/' + folder._id" class="block py-1.5 pr-2.5 pl-6" :class="{ 'router-link-exact-active': $route.params.folderId === folder._id }">
            <span>{{ folder.title }}</span>
            <span class="small-counter">
              {{ countItemsInFolder(folder._id) > 0 ? countItemsInFolder(folder._id) : ' '}}
            </span>
          </router-link>
        </div>
        <folder-form></folder-form>
        <folders-draggable
          :folders="parentFolders"
          :count="countItemsInFolder"
          :link="'calendar'"
        >
        </folders-draggable>
        <tasks-no-date :tasks="unscheduledTasks" @event-drop="handleEventDrop"/>
      </div>
    </sidebar>
    <div class="overflow-y-hidden px-0 pt-0 pb-14 w-full h-screen transition-all duration-200 ease-out lg:pb-0 lg:flex-grow" :class="{ 'lg:-ml-60': openSidebar }">
      <calendar-view
        ref="calendarView"
        :events="filteredItems"
        :weekends="settings.calendarShowWeekend"
        @view-changed="changeView"
        @date-click="handleDateClick"
        @event-click="handleEventClick"
        @event-drop="handleEventDrop"
      >
        <template #event="{ event, getFolderName, translateDate, isMonthView }">
          <div class="flex">
            <div class="flex-1" :class="{ 'overflow-hidden whitespace-nowrap overflow-clip': isMonthView }">
              <div v-if="!isMonthView && translateDate(event)" class="block mb-px text-xs text-blue-600">
                {{ translateDate(event) }}
              </div>
              <div>{{ event.title }}</div>
              <div v-if="getFolderName(event)" class="mt-1 text-xs text-gray-500">{{ getFolderName(event) }}</div>
            </div>
            <feather-icon v-if="event.repeat" name="repeat" size="16" class="mt-0.5 mr-1 -ml-1 text-gray-500" title="Repeats"/>
            <feather-icon v-if="isNote(event)" name="file-text" size="16" class="mt-0.5 mr-1 -ml-1 text-gray-500" title="Note"/>
            <task-checkbox
              v-if="isTask(event)"
              :class="[ isMonthView ? 'my-px mx-1' : 'mt-px ml-1 lg:mt-0' ]"
              :value="event.extras.object.completed"
              :priority="event.extras.object.priority"
              @input="toggleTask(event)"
            />
          </div>
        </template>
      </calendar-view>
      <modal
        v-if="showModal"
        :container-width="480"
        @close="closeModal"
      >
        <div v-if="!modalObject._id" slot="tab" class="mx-6 mt-5">
          <div class="flex toggle">
            <div class="flex-1 py-1 btn" :class="{ 'active': showEventModal }" @click="currentModal='event'">Event</div>
            <div class="flex-1 py-1 btn" :class="{ 'active': showTaskModal }" @click="currentModal='task'">Task</div>
            <div class="flex-1 py-1 btn" :class="{ 'active': showNoteModal }" @click="currentModal='note'">Note</div>
          </div>
        </div>
        <div slot="header" class="flex relative" >
          <div v-if="showTaskModal && modalObject._id" class="p-0 mt-4 w-11" >
            <task-checkbox v-model="modalObject.completed" :priority="modalObject.priority"/>
          </div>
          <div v-else class="block w-11"></div>
          <div class="flex-1">
            <editable
              ref="modalTitle"
              class="mt-2 text-2xl font-semibold pb-2 border-b border-gray-200 placeholder-gray-300 focus:pb-1.5 focus:border-blue-400"
              :class="{ 'line-through': modalObject.completed }"
              :content="modalObject._id ? modalObject.title : ''"
              placeholder="Add Title"
              @update="modalObject.title = $event"
            />
          </div>
          <a
            title="Open Note"
            v-if="showNoteModal && modalObject._id"
            class="ml-4 btn-icon-circle"
            @click.prevent="$router.push(`/app/notes/${modalObject.folderId}/${modalObject._id}`)"
          >
            <feather-icon name="external-link"/>
          </a>
        </div>
        <div slot="body">
          <div class="flex py-4 text-base border-b border-gray-300">
            <div class="mt-0.5 mr-6">
              <feather-icon name="clock" size="20" class="text-gray-400"/>
            </div>
            <div class="flex-1">
              <div class="inline-block mb-1" v-if="showEventModal">
                <v-date-picker id="startDatePicker"
                  v-model="startDate"
                  class="mr-1"
                  :attributes="attrs"
                  :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                  :masks="{ input: ['DD MMM YYYY'], weekdays: 'WWW'}"
                  :popover="{visibility: 'click'}"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="datepicker"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="01 Jan 2020"
                    />
                  </template>
                </v-date-picker>
                <timepicker v-if="!allDay" id="startTimePicker" name="startTimePicker" v-model="startTime"/>
                <span class="inline-block mr-3 ml-1"> – </span>
              </div>
              <div class="inline-block mb-1">
                <v-date-picker
                  id="endDatePicker"
                  v-model="endDate"
                  class="mr-1"
                  :attributes="attrs"
                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                  :masks="{ input: ['DD MMM YYYY'], weekdays: 'WWW' }"
                  :popover="{ visibility: 'click' }"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="datepicker"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="01 Jan 2020"
                    />
                  </template>
                </v-date-picker>
                <timepicker v-if="!allDay" id="endTimePicker" name="endTimePicker" v-model="endTime"/>
              </div>
              <div class="flex items-center leading-8">
                <form-toggle :value="allDay" @input="onChangeCheckAllDay" label="All Day" :after="true"></form-toggle>
              </div>
              <div v-if="showEventModal" class="flex items-center mt-1 leading-8">
                <v-popover placement="bottom-start">
                  <a class="flex px-2 -my-2 -mx-2 text-gray-800 bg-transparent btn hover:bg-gray-100">
                    <span class="flex-1">{{ repeatRule }}</span>
                    <feather-icon name="chevron-down" size="16" class="ml-2 text-gray-500"/>
                  </a>
                  <template slot="popover">
                    <div>
                      <ul class="pb-2 folder-option">
                        <li>Repeat</li>
                        <li v-close-popover @click="setRuleString('')"
                          :class="{'selected': modalObject.rruleString === ''}">Doesn't Repeat</li>
                        <li v-close-popover @click="setRuleString('RRULE\:FREQ\=DAILY')"
                          :class="{'selected': modalObject.rruleString === 'RRULE\:FREQ\=DAILY'}">Every day</li>
                        <li v-close-popover @click="setRuleString('RRULE\:FREQ\=WEEKLY')"
                          :class="{'selected': modalObject.rruleString === 'RRULE\:FREQ\=WEEKLY'}">Every week</li>
                        <li v-close-popover @click="setRuleString('RRULE\:FREQ\=MONTHLY')"
                          :class="{'selected': modalObject.rruleString === 'RRULE\:FREQ\=MONTHLY'}">Every month</li>
                        <li v-close-popover @click="setRuleString('RRULE\:FREQ\=YEARLY')"
                          :class="{'selected': modalObject.rruleString === 'RRULE\:FREQ\=YEARLY'}">Every year</li>
                      </ul>
                    </div>
                  </template>
                </v-popover>
              </div>
            </div>
          </div>
          <div class="flex py-4 text-base border-b border-gray-300">
            <div class="mt-1 mr-6">
              <img alt="Folder" src="@/assets/icons/icon-folder.svg" class="opacity-50" width="20"/>
            </div>
            <div class="flex flex-1 items-center">
              <v-popover placement="bottom-start">
                <a class="flex px-2 -my-2 -mx-2 text-gray-800 bg-transparent btn hover:bg-gray-100" title="Folder">
                  <span class="flex-1">{{ modalFolder.title }}</span>
                  <feather-icon name="chevron-down" size="16" class="ml-2 text-gray-500"/>
                </a>
                <template slot="popover">
                  <div>
                    <ul class="pb-2 folder-option">
                      <li>Folders</li>
                      <li v-for="folder in folders" :key="folder._id" @click="$set(modalObject, 'folderId', folder._id)" v-close-popover :class="{ 'selected': folder._id === modalFolder._id }">
                        <span>{{ folder.title }}</span>
                      </li>
                    </ul>
                  </div>
                </template>
              </v-popover>
            </div>
          </div>
          <div v-if="showEventModal" class="flex items-center py-4 text-base border-b border-gray-300">
            <div class="mr-6">
              <feather-icon name="align-left" size="20" class="text-gray-500"/>
            </div>
            <div class="flex-1">
              <editable
                :content="modalObject.description || ''"
                :allow-empty="true"
                placeholder="Add description"
                @update="$set(modalObject, 'description', $event)"
              />
            </div>
          </div>
          <div v-if="showTaskModal" class="flex items-center py-4 text-base border-b border-gray-300">
            <div class="mr-6 h-5">
              <img alt="Priority"
                :class="{ 'priority': modalObject.priority < 4 }"
                :src="require(`@/assets/icons/task/icon-priority${modalObject.priority < 4 ? `-${modalObject.priority}` : '' }.svg`)"
                :style="`opacity:${modalObject.priority < 4 ? 1 : 0.5}`"
                width="20"
              />
            </div>
            <div class="flex-1">
              <v-popover placement="bottom-start">
                <a class="px-2 -my-2 -mx-2 text-gray-800 bg-transparent btn hover:bg-gray-100">{{ priorityToWord }} Priority</a>
                <template slot="popover">
                  <div>
                    <ul class="pb-2 folder-option">
                      <li>Priority</li>
                      <li v-close-popover @click="setPriority(1)"
                        :class="{'selected': modalObject.priority === 1}">High Priority</li>
                      <li v-close-popover @click="setPriority(2)"
                        :class="{'selected': modalObject.priority === 2}">Medium Priority</li>
                      <li v-close-popover @click="setPriority(3)"
                        :class="{'selected': modalObject.priority === 3}">Low Priority</li>
                      <li v-close-popover @click="setPriority(4)"
                        :class="{'selected': modalObject.priority === 4}">No Priority</li>
                    </ul>
                  </div>
                </template>
              </v-popover>
            </div>
          </div>
          <div v-if="showNoteModal && !modalObject._id" class="flex items-center py-4 text-base border-b border-gray-300">
            <div class="mr-6 h-5">
              <img alt="Template" title="Template" src="@/assets/icons/note/icon-note-template.svg" class="opacity-50" width="20"/>
            </div>
            <div class="flex-1">
              <v-popover placement="bottom-start">
                <a class="px-2 -my-2 -mx-2 text-gray-800 bg-transparent btn hover:bg-gray-100" title="Choose Note Template">{{ chosenNoteTemplate }}</a>
                <template slot="popover">
                  <div>
                    <ul class="pb-2 folder-option">
                      <li>Templates</li>
                      <li v-close-popover @click="noteTemplate='blank'"
                        :class="{ 'selected': noteTemplate === 'blank' }">Blank Note</li>
                      <li v-close-popover @click="noteTemplate='project'"
                        :class="{ 'selected': noteTemplate === 'project' }">Project Plan</li>
                      <li v-close-popover @click="noteTemplate='journal'"
                        :class="{ 'selected': noteTemplate === 'journal' }">Daily Journal</li>
                      <li v-close-popover @click="noteTemplate='meeting'"
                        :class="{ 'selected': noteTemplate === 'meeting' }">Meeting Note</li>
                    </ul>
                  </div>
                </template>
              </v-popover>
            </div>
          </div>
        </div>
        <template slot="footer">
          <a v-show="modalObject._id" class="btn outline red" @click.prevent="handleDelete">Delete</a>
          <span class="text-sm leading-8 text-red-600" v-show="error">{{ error }}</span>
          <button v-if="modalObject._id" class="ml-auto btn outline" @click="closeModal">
            Close
          </button>
          <button v-else class="ml-auto btn outline" @click="closeModal">
            Cancel
          </button>
          <button class="ml-3 btn" @click="handleSave">
            Save
          </button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import { sortBy } from 'lodash-es'
import { format, formatISO, parseISO, startOfDay, isBefore, addHours, setHours, isMatch, isAfter, differenceInMinutes, differenceInCalendarDays, addDays } from 'date-fns'
import RRule from 'rrule'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import { getNoteTemplate, hasTime, setStringWithTime, setStringWithDate, addMinutesToString, capitalize } from '../../utils'

import Folders from '@/mixins/Folders'
import Sidebar from '@/mixins/Sidebar'
import Settings from '@/mixins/Settings'

import Modal from '@/components/Modal'
import Editable from '@/components/Editable'
import Timepicker from '@/components/Timepicker'
import CalendarView from '@/components/calendar/CalendarView'
import TasksNoDate from '@/components/TasksNoDate'
import TaskCheckbox from '@/components/TaskCheckbox'
import FormToggle from '@/components/FormToggle'

export default {
  name: 'Calendar',
  mixins: [
    Folders,
    Sidebar,
    Settings,
    makeFindMixin({ service: 'events', watch: true }),
    makeFindMixin({ service: 'tasks', watch: true })
  ],
  components: {
    CalendarView,
    Modal,
    Editable,
    'v-date-picker': DatePicker,
    Timepicker,
    TasksNoDate,
    TaskCheckbox,
    FormToggle
  },
  data() {
    return {
      pageTitle: '',
      selectedView: 'Week',
      showModal: false,
      currentModal: 'event',
      modalObject: { allDay: false, rruleString: '' },
      error: '',
      noteTemplate: 'blank',
      currentTime: new Date(),
      currentStart: null,
      currentEnd: null,
      attrs: [
        {
          key: 'today',
          highlight: {
            color: 'gray',
            fillMode: 'outline'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    ...mapGetters('notes', { findNotesInStore: 'find' }),
    ...mapGetters('tasks', {
      getTaskInStore: 'get',
      findTasksInStore: 'find'
    }),
    ...mapGetters(['connected']),
    eventsParams() { return { query: {}, temps: !this.connected } },
    eventsQueryWhen() { return this.connected },
    tasksParams() { return { query: {}, temps: !this.connected } },
    tasksQueryWhen() { return this.connected },
    startDate: {
      get() { return this.modalObject.start ? parseISO(this.modalObject.start) : parseISO(this.modalObject.end) },
      set(value) {
        // @params {value} String (yyyy-MM-dd)
        if (hasTime(this.modalObject.start)) {
          const start = parseISO(this.modalObject.start)
          const end = parseISO(this.modalObject.end)
          const originalStart = start
          this.modalObject.start = setStringWithDate(this.modalObject.start, value)
          if (isAfter(parseISO(this.modalObject.start), end)) {
            const diff = differenceInMinutes(end, originalStart)
            this.modalObject.end = addMinutesToString(this.modalObject.start, diff)
          }
        } else {
          this.modalObject.start = value
        }
      }
    },
    endDate: {
      get() { return parseISO(this.modalObject.end) },
      set(value) {
        // @params {value} String (yyyy-MM-dd)
        if (hasTime(this.modalObject.end)) {
          const start = parseISO(this.modalObject.start)
          const end = parseISO(this.modalObject.end)
          const originalEnd = end
          this.modalObject.end = setStringWithDate(this.modalObject.end, value)
          if (isBefore(parseISO(this.modalObject.end), start)) {
            const diff = differenceInMinutes(start, originalEnd) // minus
            this.modalObject.start = addMinutesToString(this.modalObject.end, diff)
          }
        } else {
          this.modalObject.end = value
        }
      }
    },
    startTime: {
      get() {
        const { start } = this.modalObject
        return start ? format(parseISO(start), 'HH:mm') : null
      },
      set(value) {
        const diff = differenceInMinutes(parseISO(this.modalObject.end), parseISO(this.modalObject.start))
        this.modalObject.start = setStringWithTime(this.modalObject.start, value)
        this.modalObject.end = addMinutesToString(this.modalObject.start, diff)
      }
    },
    endTime: {
      get() {
        const { end } = this.modalObject
        return end ? format(parseISO(end), 'HH:mm') : null
      },
      set(value) {
        this.modalObject.end = setStringWithTime(this.modalObject.end, value)
      }
    },
    allDay: {
      get() { return this.modalObject.allDay },
      set(bool) { this.modalObject.allDay = bool }
    },
    repeatRule() {
      return this.modalObject.rruleString?.length
        ? capitalize(RRule.fromString(this.modalObject.rruleString).toText())
        : 'Doesn\'t Repeat'
    },
    priorityToWord() {
      switch (this.modalObject.priority) {
        case 1: return 'High'
        case 2: return 'Medium'
        case 3: return 'Low'
        default: return 'No'
      }
    },
    chosenNoteTemplate() {
      switch (this.noteTemplate) {
        case 'project': return 'Project Plan'
        case 'journal': return 'Daily Journal'
        case 'meeting': return 'Meeting Note'
        default: return 'Blank Note'
      }
    },
    showEventModal() {
      return this.currentModal === 'event'
    },
    showTaskModal() {
      return this.currentModal === 'task'
    },
    showNoteModal() {
      return this.currentModal === 'note'
    },
    modalFolder() {
      if (this.modalObject.folderId) {
        return this.folders.find(folder => folder._id === this.modalObject.folderId)
      }
      return this.folders[0]
    },
    allItems() {
      return this.events
        .concat(this.calendarShowTasks ? this.tasksOnCalendar : [])
        .concat(this.calendarShowNotes ? this.notesOnCalendar : [])
    },
    filteredItems() {
      return this.filterByFolder(this.allItems.map(item => ({
        ...item,
        extras: {
          ...item.extras,
          color: item.color
        }
      })))
    },
    tasksOnCalendar() {
      return this.findTasksInStore({ query: { endDate: { $ne: null } } }).data
        .map((task) => {
          return {
            _id: task._id,
            end: task.endDate,
            allDay: !hasTime(task.endDate),
            title: task.title,
            folderId: task.folderId,
            color: this.taskColor(task),
            editable: true,
            extras: {
              type: 'task',
              object: task
            }
          }
        }
        )
    },
    unscheduledTasks() {
      const tasks = this.findTasksInStore({ query: { completed: false, endDate: { $in: ['', null] } } }).data
        .map((task) => ({
          _id: task._id,
          end: task.endDate,
          allDay: !hasTime(task.endDate),
          title: task.title,
          folderId: task.folderId,
          color: this.taskColor(task),
          editable: true,
          extras: {
            type: 'task',
            object: task
          }
        }))

      return sortBy(this.filterByFolder(tasks), [
        'extras.object.priority'
      ])
    },
    notesOnCalendar() {
      return this.findNotesInStore({ query: { endDate: { $ne: null } } }).data.map(note => ({
        _id: note._id,
        end: note.endDate,
        allDay: !hasTime(note.endDate),
        title: note.title,
        folderId: note.folderId,
        color: '#F8F8F8',
        borderColor: '#1E1E1E',
        textColor: '#1E1E1E',
        editable: true,
        extras: {
          type: 'note',
          object: note
        }
      }))
    },
    countAllEvents() {
      return this.allItems.filter(
        e => isAfter(new Date(e.end), startOfDay(new Date()))
      ).length
    },
    calendarShowTasks() {
      return this.settings.calendarShowTasks
    },
    calendarShowNotes() {
      return this.settings.calendarShowNotes
    }
  },
  methods: {
    countItemsInFolder(folderId) {
      // only count items after today
      return this.allItems
        .filter(e => e.folderId === folderId)
        .filter(
          e => isAfter(new Date(e.end), startOfDay(new Date()))
        ).length
    },
    filterByFolder(items) {
      if (this.$route.params.folderId !== 'all') {
        return items.filter(item => item.folderId === this.$route.params.folderId)
      }
      return items
    },
    closeModal() {
      this.showModal = false
      this.currentModal = 'event'
      this.modalObject = { allDay: false, rruleString: '' }
      this.error = ''
    },
    onChangeCheckAllDay(val) {
      this.modalObject.allDay = val
      if (val) {
        console.log('change check 1', this.modalObject.start, this.modalObject.end)
        this.currentStart = this.modalObject.start
        this.currentEnd = this.modalObject.end
        this.modalObject.start = this.currentStart ? format(parseISO(this.modalObject.start), 'yyyy-MM-dd') : this.currentStart
        this.modalObject.end = this.currentEnd ? format(parseISO(this.modalObject.end), 'yyyy-MM-dd') : this.currentEnd
      } else {
        console.log('change check 2', this.modalObject.start, this.modalObject.end)
        if (this.showEventModal) {
          this.modalObject.start = this.currentStart || formatISO(setHours(parseISO(this.modalObject.end), 9))
          this.modalObject.end = this.currentEnd || formatISO(addHours(parseISO(this.modalObject.start), 2))
        } else {
          this.modalObject.start = null
          this.modalObject.end = this.currentEnd || formatISO(setHours(parseISO(this.modalObject.end), 9))
        }
        this.currentStart = null
        this.currentEnd = null
      }
    },
    mapEventArgs(args) {
      const { event } = args
      return {
        start: event.start ?? null,
        end: event.end ?? null,
        allDay: event.allDay ?? (event.end && event.start === null),
        _id: event._id,
        title: event.title,
        description: event.description ?? '',
        folderId: event.folderId,
        rruleString: event.rruleString,
        extras: event.extras
      }
    },
    handleDateClick(args) {
      if (args.start) {
        this.modalObject.start = args.start
      }
      this.modalObject.end = args.end
      this.modalObject.allDay = args.allDay
      this.showModal = true
      this.currentModal = 'event'
      this.$nextTick(() => { this.$refs.modalTitle.focus() })
    },
    handleEventClick(args) {
      this.showModal = true
      if (args.event.extras?.type === 'task') {
        this.modalObject = {
          ...args.event.extras.object,
          end: args.event.extras.object.endDate,
          allDay: args.event.allDay
        }
        this.currentModal = 'task'
      } else if (args.event.extras?.type === 'note') {
        this.modalObject = {
          ...args.event.extras.object,
          end: args.event.extras.object.endDate,
          allDay: args.event.allDay
        }
        this.currentModal = 'note'
      } else {
        this.modalObject = this.mapEventArgs(args)
        this.currentModal = 'event'
      }
    },
    handleEventDrop(args) {
      const { event } = args
      if (event.extras?.type === 'task') {
        this.modalObject = event.extras.object
        this.modalObject.allDay = event.allDay
        this.modalObject.end = hasTime(event.end) ? setStringWithDate(event.end, args.end) : args.end
        this.saveTask()
      } else if (event.extras?.type === 'note') {
        this.modalObject = event.extras.object
        this.modalObject.allDay = event.allDay
        this.modalObject.end = hasTime(event.end) ? setStringWithDate(event.end, args.end) : args.end
        this.saveNote()
      } else {
        let currentStart = event.start
        let currentEnd = event.end
        const end = args.end
        if (currentStart !== currentEnd) {
          if (hasTime(currentEnd)) {
            const diff = differenceInMinutes(parseISO(currentStart), parseISO(currentEnd))
            currentEnd = setStringWithDate(currentEnd, end)
            currentStart = addMinutesToString(currentEnd, diff)
          } else {
            const days = differenceInCalendarDays(parseISO(currentStart), parseISO(currentEnd))
            currentEnd = end
            currentStart = format(addDays(parseISO(end), days), 'yyyy-MM-dd')
          }
        } else {
          currentStart = end
          currentEnd = end
        }
        this.modalObject = {
          ...this.mapEventArgs(args),
          start: currentStart,
          end: currentEnd
        }
        this.saveEvent()
      }
    },
    handleKeyFunction(event) {
      if (!this.showModal) {
        switch (event.key) {
          case 'ArrowLeft':
            this.$refs.calendarView.prev()
            break
          case 'ArrowRight':
            this.$refs.calendarView.next()
            break
          case 't':
            this.$refs.calendarView.today()
            break
          case 'm':
            this.changeView('month')
            break
          case 'w':
            this.changeView('week')
            break
        }
      }
    },
    handleViewRoute() {
      if (this.$route.params.view) {
        this.changeView(this.$route.params.view)
      }
    },
    changeView(view) {
      if (this.$route.params.view !== view) {
        this.$router.replace({ params: { ...this.$route.params, view } })
      }
      this.$refs.calendarView.setCurrentView(view)
    },
    handleSave() {
      switch (this.currentModal) {
        case ('event'):
          this.saveEvent()
          break
        case ('task'):
          this.saveTask()
          break
        case ('note'):
          this.saveNote()
          break
      }
    },
    handleDelete() {
      switch (this.currentModal) {
        case ('event'):
          this.deleteEvent()
          break
        case ('task'):
          this.deleteTask()
          break
        case ('note'):
          this.deleteNote()
          break
      }
    },
    setRuleString(ruleString) {
      this.modalObject.rruleString = ruleString
    },
    saveEvent() {
      const { Event } = this.$FeathersVuex.api
      const { title, _id, allDay, start, end, description, color } = this.modalObject
      if (title) {
        this.error = ''
        if (_id) {
          if (allDay) {
            this.modalObject.start = isMatch(start, 'yyyy-MM-dd') ? start : start ? format(parseISO(start), 'yyyy-MM-dd') : null
            this.modalObject.end = isMatch(end, 'yyyy-MM-dd') ? end : format(parseISO(end), 'yyyy-MM-dd')
          }
          const { Event } = this.$FeathersVuex.api
          const event = new Event({
            id: _id,
            start: this.modalObject.start,
            end: this.modalObject.end,
            allDay: this.allDay,
            rruleString: this.modalObject.rruleString,
            title: title,
            description: description,
            folderId: this.modalFolder._id,
            color: color
          })
          event.patch().then(() => {
            this.closeModal()
          })
        } else {
          const event = new Event({
            start: this.modalObject.start,
            end: this.modalObject.end,
            allDay: this.allDay,
            rruleString: this.modalObject.rruleString,
            title: title,
            description: description,
            folderId: this.modalFolder._id,
            color: color
          })
          event.save().then(() => {
            this.closeModal()
          })
        }
      } else {
        this.error = 'Event title cannot be empty'
      }
    },
    deleteEvent() {
      const result = confirm('Want to delete?')
      if (result) {
        const { Event } = this.$FeathersVuex.api
        const deleteEvent = new Event({ _id: this.modalObject._id })
        deleteEvent.remove().then(() => {
          this.closeModal()
        }).catch(err => console.error('delete ' + err))
      }
    },
    isTask(event) {
      return event.extras?.type === 'task'
    },
    isNote(event) {
      return event.extras?.type === 'note'
    },
    toggleTask(event) {
      const { object } = event.extras
      const task = this.getTaskInStore(object._id)
      task.patch({ data: { completed: !object.completed } })
    },
    taskColor(task) {
      switch (task.priority) {
        case 1:
          return task.completed ? '#DE0B0B' : '#DE0B0B'
        case 2:
          return task.completed ? '#FCA80F' : '#FCA80F'
        case 3:
          return task.completed ? '#0058FF' : '#0058FF'
        default:
          return '#CCCCCC'
      }
    },
    setPriority(num) {
      this.$set(this.modalObject, 'priority', num)
    },
    saveTask() {
      if (this.modalObject.allDay) {
        this.modalObject.end = this.modalObject.end ? format(parseISO(this.modalObject.end), 'yyyy-MM-dd') : null
      }
      const { Task } = this.$FeathersVuex.api
      if (this.modalObject.title) {
        this.error = ''
        if (this.modalObject._id) {
          const task = new Task({
            id: this.modalObject._id,
            title: this.modalObject.title,
            folderId: this.modalFolder._id,
            completed: this.modalObject.completed,
            priority: this.modalObject.priority,
            endDate: this.modalObject.end
          })
          task.patch().then(() => {
            this.closeModal()
          })
        } else {
          const task = new Task({
            title: this.modalObject.title,
            folderId: this.modalFolder._id,
            completed: this.modalObject.completed,
            priority: this.modalObject.priority,
            endDate: this.modalObject.end
          })
          task.save().then(() => {
            this.closeModal()
          })
        }
      } else {
        this.error = 'Task title cannot be empty'
      }
    },
    deleteTask() {
      const result = confirm('Want to delete?')
      if (result) {
        const task = new this.$FeathersVuex.api.Task({ _id: this.modalObject._id })
        task.remove().then(() => {
          this.closeModal()
        }).catch(err => console.error('delete ' + err))
      }
    },
    saveNote() {
      const { Note } = this.$FeathersVuex.api
      if (this.modalObject.title) {
        this.error = ''
        if (this.modalObject._id) {
          const note = new Note({
            id: this.modalObject._id,
            title: this.modalObject.title,
            folderId: this.modalFolder._id,
            endDate: this.modalObject.end
          })
          note.patch().then(() => this.closeModal())
        } else {
          const note = new Note({
            title: this.modalObject.title,
            folderId: this.modalFolder._id,
            endDate: this.modalObject.end,
            content: getNoteTemplate(this.noteTemplate)
          })
          note.save().then((createdNote) => {
            this.closeModal()
            this.$store.commit('setSelectedNoteId', createdNote._id)
            this.$router.push({ path: `/app/notes/${createdNote.folderId}/${createdNote._id}` })
          })
        }
      } else {
        this.error = 'Note title cannot be empty'
      }
    },
    deleteNote() {
      const note = new this.$FeathersVuex.api.Note({ _id: this.modalObject._id })
      note.remove().then(() => this.closeModal())
    }
  },
  mounted() {
    setInterval(() => { this.currentTime = new Date() }, 1000 * 60)
    window.addEventListener('keyup', this.handleKeyFunction)
    this.handleViewRoute()
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyFunction)
  }
}
</script>
