import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Task extends BaseModel {
  static modelName = 'Task'

  static instanceDefaults() {
    return {
      title: '',
      folderId: '',
      endDate: '',
      priority: 4,
      completed: false
    }
  }
}

const servicePath = 'tasks'
const servicePlugin = makeServicePlugin({
  Model: Task,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
