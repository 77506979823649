<template>
  <div id="desk" class="lg:flex">
    <div class="flex flex-col flex-1 my-0 mx-auto max-w-6xl">
      <div class="pb-14 lg:pb-0 lg:px-10 lg:pt-12 lg:flex">
        <desk-tasks/>
        <desk-notes/>
      </div>
    </div>
  </div>
</template>

<script>
import DeskNotes from '@/components/DeskNotes'
import DeskTasks from '@/components/DeskTasks'

export default {
  name: 'Desk',
  components: {
    DeskNotes,
    DeskTasks
  }
}
</script>
