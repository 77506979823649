import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Note extends BaseModel {
  static modelName = 'Note'

  static instanceDefaults() {
    return {
      title: '',
      content: '',
      pinned: false,
      archived: false,
      taskSync: true
    }
  }
}

const servicePath = 'notes'
const servicePlugin = makeServicePlugin({
  Model: Note,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
