import { addMinutes, format, formatISO, intervalToDuration, isDate, isSameDay, parseISO, set } from 'date-fns'
// import { zonedTimeToUtc } from 'date-fns-tz'

function addZero(x, n) {
  while (x.toString().length < n) { x = '0' + x }
  return x
}

function timestamp() {
  var d = new Date()
  var h = addZero(d.getHours(), 2)
  var m = addZero(d.getMinutes(), 2)
  var s = addZero(d.getSeconds(), 2)
  var ms = addZero(d.getMilliseconds(), 3)
  return h + ':' + m + ':' + s + '.' + ms
}

function getToday() {
  return format(new Date(), 'yyyy-MM-dd')
}

function getDayEvents(events, date) {
  return events.filter(e => isSameDay(toDate(e.repeat ? e.repeatEnd : e.end), date))
  // Show all occurences in events with duration
  // return events.filter(e => {
  //   const start = isDate(e.start) ? e.start : parseISO(e.start)
  //   const end = isDate(e.end) ? e.end : parseISO(e.end)
  //   return isSameDay(start, date) || (isAfter(date, start) && isBefore(date, end)) || isSameDay(end, date)
  // })
}

function hasTime(date) {
  // @param {date} String
  return date && date.length > 10 && date.split('T').length === 2
}

function dateToFullString(date) {
  // Returns date in format 'yyyy-MM-ddTHH:mm:ssZ'
  if (isDate(date)) {
    return formatISO(date)
  }
}

function dateToShortString(date) {
  if (isDate(date)) {
    // 'yyyy-MM-dd'
    return formatISO(date, { representation: 'date' })
  }
}

function tz() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function toUTC(date) {
  return addMinutes(date, date.getTimezoneOffset())
}

function toDate(date) {
  // Date is string or Date object
  return isDate(date) ? date : parseISO(date)
}

function toHourMinute(date) {
  return format(new Date(date), 'HH:mm')
}

function getHourFromTime(time) {
  return time.split(':')[0]
}

function getMinuteFromTime(time) {
  return time.split(':')[1]
}

function setStringWithTime(string, time) {
  if (!string || !time) { return }
  return formatISO(set(parseISO(string), {
    hours: getHourFromTime(time),
    minutes: getMinuteFromTime(time)
  }))
}

function setStringWithDate(string, date) {
  if (!string || !date) { return }
  return date + 'T' + string.split('T')[1]
}

function addMinutesToString(string, minutes) {
  return formatISO(addMinutes(parseISO(string), minutes))
}

function getDurationDays(start, end) {
  if (isDate(start) && isDate(end)) {
    return intervalToDuration({ start, end }).days
  }
  return 1
}

function capitalize(t) {
  return t.charAt(0).toUpperCase() + t.slice(1)
}

function until(conditionFunction) {
  const poll = resolve => {
    if (conditionFunction()) resolve()
    else setTimeout(_ => poll(resolve), 400)
  }
  return new Promise(poll)
}

function getNoteTemplate(type) {
  switch (type) {
    case 'meeting':
      return '<h1>Goal</h1><p>Main meeting goals</p><h2>Attendees</h2><ol><li><p></p></li><li><p></p></li><li><p></p></li></ol><h2>Agenda</h2><ol><li><p></p></li><li><p></p></li><li><p></p></li></ol><h2>Notes</h2><ul><li><p></p></li><li><p></p></li><li><p></p></li></ul><h2>Actions</h2><ul data-type="todo_list"><li data-type="todo_item" data-done="false" data-block-id="" data-priority="4"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p></p></div><div class="todo-actions" contenteditable="false"></div></li><li data-type="todo_item" data-done="false" data-block-id="" data-priority="4"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p></p></div><div class="todo-actions" contenteditable="false"></div></li><li data-type="todo_item" data-done="false" data-block-id="" data-priority="4"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p></p></div><div class="todo-actions" contenteditable="false"></div></li></ul><p></p>'
    case 'journal':
      return '<h3>What went well?</h3><ol><li><p></p></li><li><p></p></li></ol><h3>What didn\'t go well?</h3><ol><li><p></p></li><li><p></p></li></ol><h3>How tomorrow can be better?</h3><ol><li><p></p></li><li><p></p></li></ol><h3>Most important things to get done:</h3><ul data-type="todo_list"><li data-type="todo_item" data-done="false" data-block-id="" data-priority="4"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p></p></div><div class="todo-actions" contenteditable="false"></div></li><li data-type="todo_item" data-done="false" data-block-id="" data-priority="4"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p></p></div><div class="todo-actions" contenteditable="false"></div></li></ul><p></p>'
    case 'project':
      return '<h2>Summary</h2><p>Explain about the project</p><h2>Preparations</h2><ul><li><p></p></li><li><p></p></li><li><p></p></li></ul><h2>Milestones</h2><ol><li><p></p></li><li><p></p></li><li><p></p></li></ol><h2>Tasks to get done</h2><ul data-type="todo_list"><li data-type="todo_item" data-done="false" data-block-id="" data-priority="4"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p></p></div><div class="todo-actions" contenteditable="false"></div></li><li data-type="todo_item" data-done="false" data-block-id="" data-priority="4"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p></p></div><div class="todo-actions" contenteditable="false"></div></li><li data-type="todo_item" data-done="false" data-block-id="" data-priority="4"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p></p></div><div class="todo-actions" contenteditable="false"></div></li></ul><p></p>'
    case 'blank':
    default:
      return ''
  }
}

export {
  timestamp,
  getToday,
  getDayEvents,
  hasTime,
  dateToFullString,
  dateToShortString,
  tz,
  toUTC,
  toDate,
  toHourMinute,
  getHourFromTime,
  getMinuteFromTime,
  setStringWithTime,
  setStringWithDate,
  addMinutesToString,
  getDurationDays,
  capitalize,
  until,
  getNoteTemplate
}
