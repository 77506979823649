<template>
  <div id="tasks" class="lg:flex">
    <sidebar>
      <div class="sidebar-menu-wrapper">
        <div class="pt-3 folder">
          <router-link to="/app/tasks/all" class="block py-1.5 pr-2.5 pl-6">
            <span>All Tasks</span>
            <span class="small-counter">
              {{ allTasksCount > 0 ? allTasksCount : '' }}
            </span>
          </router-link>
        </div>
        <div class="folder" v-for="folder in folders.slice(0, 1)" :key="folder._id">
          <router-link :to="'/app/tasks/' + folder._id" class="block py-1.5 pr-2.5 pl-6">
            <span>{{ folder.title }}</span>
            <span class="small-counter">
              {{ tasksCount(folder._id) > 0 ? tasksCount(folder._id) : '' }}
            </span>
          </router-link>
        </div>
        <folder-form></folder-form>
        <folders-draggable
          :folders="parentFolders"
          :count="tasksCount"
          :link="'tasks'"
        >
        </folders-draggable>
        <div class="pb-6 mt-auto folder">
          <router-link to="/app/tasks/completed" class="block py-1.5 pr-2.5 pl-6">
            <span>Completed</span>
          </router-link>
        </div>
      </div>
    </sidebar>
    <div class="overflow-y-auto pr-3 pb-3 pl-2 max-h-screen transition-all duration-200 ease-out lg:flex-grow lg:pr-8 lg:pb-8 lg:pt-12 lg:pl-12" :class="{ 'lg:-ml-60': openSidebar }">
      <div class="relative mb-36">
        <div class="absolute w-full ml-0.5 lg:ml-0 group">
          <div v-click-outside="onSearchBlur" class="relative mx-auto w-full max-w-4xl h-10">
            <div class="absolute right-0 transition-all duration-300 ease-out" :class="[ searchOpen ? 'w-full' : 'w-10 lg:w-40' ]">
              <input type="text" ref="search" placeholder="Search" v-model="searchQuery" @keyup.esc="toggleSearch" @keyup.enter="$event.target.blur()" @focus="onSearchFocus" class="block px-4 w-full h-10 text-base placeholder-transparent bg-none rounded-full border focus:border-gray-500 hover:border-gray-500 lg:border-gray-300 lg:placeholder-gray-400" :class="[ searchOpen ? 'border-gray-300 placeholder-gray-400' : 'border-transparent' ]"/>
            </div>
            <span v-if="searchOpen" class="absolute top-0 right-0 z-10 p-2 leading-3 opacity-50 cursor-pointer lg:py-2 lg:px-3 group-hover:opacity-75" title="Press ESC to close" @click.prevent="toggleSearch">
              <img src="../../assets/icons/icon-close.svg"/>
            </span>
            <span v-else class="absolute top-0 right-0 z-10 p-2 leading-3 opacity-50 cursor-pointer lg:py-2 lg:px-3 group-hover:opacity-75" title="Search Note" @click.prevent="toggleSearch">
              <img src="../../assets/icons/icon-search.svg"/>
            </span>
          </div>
        </div>
        <div v-if="!searchOpen" class="block absolute lg:hidden">
          <a @click.prevent="$store.commit('toggleSidebar')" class="btn-icon-circle" title="Menu">
            <img src="@/assets/icons/icon-nav-menu.svg"/>
          </a>
        </div>
        <div>
          <div class="flex pl-12 mx-auto mt-1 mb-2 max-w-4xl lg:pl-4">
            <h1 class="my-0 h-10 transition-all ease-out page-headline" :class="{ 'pointer-events-none opacity-0 transform -translate-x-20': searchOpen }">{{ pageTitle }}</h1>
            <!-- <div v-show="!searchOpen && isFindTasksPending" class="inline-flex relative p-2 ml-4 w-10 h-10">
              <loading size="small"/>
            </div> -->
          </div>
          <div class="mx-auto max-w-4xl">
            <task-form v-show="showTaskForm" :folders="sortedFolders" :selected-folder="selectedFolder"></task-form>
            <task v-show="!(searchOpen && !searchQuery)" v-for="(task, index) in filteredTasks" :key="`${task._id}-${index}`" :task="task" :folders="sortedFolders" :selected-folder="selectedFolder"></task>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import { sortBy } from 'lodash-es'
import vClickOutside from 'v-click-outside'
import { getToday } from '../../utils'

import Folders from '@/mixins/Folders'
import Sidebar from '@/mixins/Sidebar'

import Task from '@/components/Task'
import TaskForm from '@/components/TaskForm'
import { parseISO } from 'date-fns'

export default {
  name: 'Tasks',
  mixins: [
    Folders,
    Sidebar,
    makeFindMixin({ service: 'tasks', watch: true })
  ],
  components: { Task, TaskForm },
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      searchOpen: false,
      lastFolderId: '',
      searchQuery: ''
    }
  },
  watch: {
    '$route.params.folderId': {
      handler: function (folderId) {
        if (folderId !== 'search') {
          this.searchQuery = ''
          this.searchOpen = false
        } else {
          this.searchOpen = true
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['connected']),
    ...mapGetters('tasks', { countTasksInStore: 'count' }),
    getToday,
    tasksParams() {
      const query = { $sort: { priority: 1, updatedAt: 1 } }
      if (this.$route.params.folderId !== 'completed') { query.completed = false }
      return { query, temps: !this.connected }
    },
    tasksQueryWhen() { return this.connected },
    allTasksCount() {
      return this.countTasksInStore({ query: { completed: false } })
    },
    tasksCount() {
      return (folderId) => this.countTasksInStore({ query: { completed: false, folderId: folderId } })
    },
    filteredTasks() {
      if (this.$route.params.folderId === 'completed') {
        return this.sortByUpdated(this.findTasksInStore({ query: { completed: true } }).data)
      } else if (this.$route.params.folderId === 'all') {
        return this.sortTasks(this.findTasksInStore({ query: { completed: false } }).data)
      } else if (this.$route.params.folderId === 'search') {
        return this.sortTasks(this.tasks.filter(task => !task.completed &&
          task.title.toLowerCase().includes(this.searchQuery.toLowerCase())))
      } else {
        return this.sortTasks(this.findTasksInStore({ query: { completed: false, folderId: this.$route.params.folderId } }).data)
      }
    },
    pageTitle() {
      const { folderId } = this.$route.params
      const title = {
        search: ' ',
        completed: 'Completed',
        all: 'All Tasks'
      }
      return title[folderId] ?? this.selectedFolder.title
    },
    showTaskForm() {
      return ['search', 'completed'].indexOf(this.$route.params.folderId) < 0
    }
  },
  methods: {
    sortTasks(arr) {
      return sortBy(arr, [
        a => a.endDate ? -1 : 1,
        a => parseISO(a.endDate),
        'priority',
        a => parseISO(a.createdAt)
      ])
    },
    sortByUpdated(arr) {
      return arr.sort((a, b) => new Date(b.updatedAt) > new Date(a.updatedAt))
    },
    onSearchFocus() {
      if (!this.searchOpen && this.$route.params.folderId !== 'search') {
        this.lastFolderId = this.$route.params.folderId
        this.$router.push('/app/tasks/search')
      }
    },
    onSearchBlur() {
      if (this.searchOpen && this.searchQuery.length === 0) {
        this.$refs.search.blur()
        this.$router.push(`/app/tasks/${this.lastFolderId}`)
      }
    },
    toggleSearch() {
      if (this.searchOpen) {
        this.$refs.search.blur()
        this.$router.push(`/app/tasks/${this.lastFolderId}`)
      } else {
        this.$refs.search.focus()
      }
    }
  }
}
</script>
