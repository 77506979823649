import Vue from 'vue'
import Router from 'vue-router'
import { timestamp } from './utils'

import MainApp from './views/private/MainApp.vue'
import Desk from './views/private/Desk.vue'
import Tasks from './views/private/Tasks.vue'
import Notes from './views/private/Notes.vue'
import Calendar from './views/private/Calendar.vue'

import feathers from './feathers-client'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('./views/public/PublicApp.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('./views/public/Home.vue')
        },
        {
          path: 'features',
          name: 'Features',
          component: () => import('./views/public/Features.vue')
        },
        {
          path: 'pricing',
          name: 'Pricing',
          component: () => import('./views/public/Pricing.vue')
        },
        {
          path: 'register',
          name: 'Register',
          component: () => import('./views/public/Register.vue')
        },
        {
          path: 'login/:type?',
          name: 'Login',
          component: () => import('./views/public/Login.vue')
          // beforeEnter: (to, from, next) => {
          //   feathers.reAuthenticate().then(result => {
          //     store.dispatch('auth/responseHandler', result)
          //     if (to.query.redirect) {
          //       next(to.query.redirect)
          //     } else {
          //       next('/app/desk')
          //     }
          //   }).catch(() => {
          //     next()
          //   })
          // }
        },
        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          component: () => import('./views/public/ForgotPassword.vue')
        },
        {
          path: 'resend-verification',
          name: 'ResendVerification',
          component: () => import('./views/public/ResendVerification.vue')
        }
      ]
    },
    {
      path: '/app',
      component: MainApp,
      beforeEnter: mainAppGuard,
      children: [
        {
          path: '',
          name: 'app',
          redirect: { path: 'desk' }
        },
        {
          path: 'desk',
          name: 'Desk',
          component: Desk
        },
        {
          path: 'tasks',
          name: 'Tasks',
          redirect: { path: 'tasks/all' }
        },
        {
          path: 'tasks/:folderId',
          name: 'TasksProject',
          component: Tasks,
          alias: 'Tasks'
        },
        {
          path: 'notes',
          name: 'Notes',
          redirect: { path: 'notes/all' }
        },
        {
          path: 'notes/:folderId',
          name: 'NotesProject',
          component: Notes,
          alias: 'Notes',
          meta: {
            showNoteList: true
          }
        },
        {
          path: 'notes/:folderId/:noteId',
          name: 'Note',
          component: Notes,
          alias: 'Note',
          meta: {
            showNoteList: false
          }
        },
        {
          path: 'calendar',
          name: 'Calendar',
          redirect: { path: 'calendar/all' }
        },
        {
          path: 'calendar/:folderId/:view?',
          name: 'CalendarProject',
          component: Calendar,
          alias: 'Calendar'
        },
        {
          path: 'settings',
          name: 'Settings',
          component: () => import('./views/private/Settings.vue')
        }
      ]
    }
  ]
})

function mainAppGuard(to, from, next) {
  feathers.reAuthenticate().then(result => {
    console.log(timestamp() + ' online reAuth')
    if (result.authentication.payload.exp < Date.now() / 1000) {
      console.log(timestamp() + ' online reAuth but expired')
      store.dispatch('auth/logout')
        .then(() => {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
    } else {
      store.dispatch('auth/responseHandler', result)
      store.dispatch('sync')
      next()
    }
  }).catch((e) => {
    console.log(timestamp() + ' online reAuth failed', e)
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  })
}

export default router
