import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '../feathers-client'
import { timestamp } from '../utils'
import { formatISO, startOfWeek } from 'date-fns'

import authPlugin from './store.auth'

Vue.use(Vuex)
Vue.use(FeathersVuex)

const requireModule = require.context('./services', false, /.js$/)
const servicePlugins = requireModule.keys()
  .map(modulePath => requireModule(modulePath).default)

export default new Vuex.Store({
  plugins: [
    ...servicePlugins,
    authPlugin
  ],
  state: {
    connected: false,
    sync: false,
    loadProgress: 0,
    openSidebar: false,
    selectedFolder: {},
    selectedNoteId: null
  },
  getters: {
    connected: state => state.connected
  },
  mutations: {
    toggleSidebar(state, bool) {
      if (bool === undefined) {
        state.openSidebar = !state.openSidebar
      } else {
        state.openSidebar = bool
      }
    },
    selectFolder: (state, folder) => {
      state.selectedFolder = folder
    },
    setSelectedNoteId: (state, noteId) => {
      state.selectedNoteId = noteId
    },
    setConnected: (state, bool) => {
      state.connected = bool
    },
    setLoading: (state, step) => {
      state.loadProgress = step
    },
    setSync: (state, bool) => {
      state.sync = bool
    }
  },
  actions: {
    async sync({ commit, dispatch }) {
      try {
        commit('setLoading', 10)
        await dispatch('folders/find')
        commit('setLoading', 20)
        await dispatch('tasks/find', { query: { completed: false } })
        commit('setLoading', 40)
        await dispatch('events/find', { query: { end: { $gt: formatISO(startOfWeek(new Date())) } } })
        commit('setLoading', 60)
        await dispatch('notes/find', { query: { archived: false, pinned: true } })
        commit('setLoading', 80)
        await dispatch('notes/find', { query: { archived: false, pinned: false, $limit: 10, $sort: { updatedAt: -1 } } })
        console.log(timestamp() + ' done sync')
      } catch (e) {
        console.log(timestamp() + ' error sync', e.message)
      } finally {
        commit('setLoading', 100)
      }
    },
    reconnectSync({ commit, dispatch }) {
      commit('setSync', true)
      Promise.all([
        dispatch('tasks/continueQueue'),
        dispatch('events/continueQueue'),
        dispatch('notes/continueQueue'),
        dispatch('folders/continueQueue'),
        // dispatch('tasks/removeTempsOnReconnect'),
        // dispatch('events/removeTempsOnReconnect'),
        // dispatch('notes/removeTempsOnReconnect'),
        // dispatch('folders/removeTempsOnReconnect'),
        dispatch('tasks/find'),
        dispatch('events/find'),
        dispatch('notes/find'),
        dispatch('folders/find')
      ]).finally(() => {
        console.log('done sync')
        commit('setSync', false)
      })
    }
  }
})
